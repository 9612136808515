<template>
  <div class="surfaceHome flex-column-between">
    <entrance @renewal="renewal" @changTip="changTip"></entrance>
    <!-- <header class="flex-align-between head">
      <div class="avatar flex-align">
        <div class="avatarImgb">
          <img class="avatarImg" :src="avatar1" v-if="succeed" @click="goUser" />
          <img class="avatarImg" src="@/assets/user/user.png" v-else @click="goUser" />
        </div>
        <div class="avatarname flex-column-between">
          <p id="tn">
            {{ uesrName }}
          </p>
          <p>上课时长：{{ time(teacherTime) }}</p>
          <p>上课字数：{{ teachercount }} 个</p>
        </div>
        <div class="type flex-column-center">
          <img src="@/assets/live/1.png" />
          <span>首页</span>
        </div>
      </div>
      <div class="back flex-align-between">
        <img :src="require('@/assets/task/return.png')" @click="goback" />
        <span @click="goback">返回</span>
      </div>
    </header> -->
    <div class="mian">
      <div class="iput flex-align-center">{{ topTitle }}</div>
      <div class="screen flex-column">
        <div
          class="screenType flex-align"
          :class="{ btncolor: screenIndex == index }"
          v-for="(item, index) in screenList"
          :key="index"
          @click="current(index, item)"
        >
          <div v-if="item != '作品管理'"></div>
          <span v-if="item != '作品管理'">{{ item }}</span>
          <span class="spanStyle" v-else>{{ item }}</span>
        </div>
      </div>
      <div class="surface">
        <div class="iput2 flex-align-center" v-if="rightTitle" @click="goEvaluation">
          {{ rightTitle }}
        </div>
        <div class="surfaceMian flex-column">
          <div class="choice flex-align">
            <span>选择：</span>
            <!-- 班级 -->
            <div class="Basics" v-if="classgrades.includes(screenName)">
              <el-select
                v-model="classValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeClass"
              >
                <el-option
                  v-for="item in classOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 项目 -->
            <div class="Basics" v-if="projectgrades.includes(screenName)">
              <el-select
                v-model="projectValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeProject"
              >
                <el-option
                  v-for="(item, index) in projectOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 分组 -->
            <div class="Basics" v-if="groupgrades.includes(screenName)">
              <el-select
                v-model="groupValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeGroup"
              >
                <el-option
                  v-for="(item, index) in groupOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 活动 -->
            <div class="Basics" v-if="activitygrades.includes(screenName)">
              <el-select
                v-model="activityValue"
                placeholder="请选择"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in activityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 任务 -->
            <div class="Basics" v-if="taskgrades.includes(screenName)">
              <el-select
                v-model="taskValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeTask"
              >
                <el-option
                  v-for="(item, index) in taskOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
            <!-- AI测评等级 -->
            <div class="Basics" v-if="Gradegrades.includes(screenName)">
              <el-select
                v-model="GradeValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeLevel"
              >
                <el-option
                  v-for="(item, index) in GradeOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 推优情况 -->
            <div class="Basics" v-if="situationgrades.includes(screenName)">
              <el-select
                v-model="situationValue"
                placeholder="请选择"
                :popper-append-to-body="false"
                @change="changeExcellent"
              >
                <el-option
                  v-for="(item, index) in situationOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 时间 -->
            <div class="Basics" v-if="Timegrades.includes(screenName)">
              <el-date-picker
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeDate"
              >
              </el-date-picker>
            </div>
            <div class="search flex-align-between">
              <el-input
                v-model="inputValue"
                :placeholder="
                  $route.query.type == 1 ? '输入手机号搜索' : '输入用户名搜索'
                "
              ></el-input>
              <div class="searchBtn flex-align-center" @click="search">
                搜索
              </div>
            </div>
          </div>
          <div class="sampleReels">
            <div class="content" v-if="workList.length > 0">
              <div
                class="contentbox flex-column-between"
                v-for="(item, index) in workList"
                :key="index"
              >
                <div class="user flex-align">
                  <img :src="item.userAvatar" alt="" v-if="item.userAvatar" />
                  <img src="@/assets/user/user.png" alt="" v-else />
                  <span>{{ item.userName ? item.userName : "未知" }}</span>
                </div>
                <div class="opus flex-align-center">
                  <img
                    class="opus_Icon"
                    src="../../assets/assistant/excellent.png"
                    v-if="item.score >= 80"
                  />
                  <img
                    class="opus_Icon"
                    src="../../assets/assistant/well.png"
                    v-if="item.score >= 65 && item.score < 80"
                  />
                  <img
                    class="opus_Icon"
                    src="../../assets/assistant/Toimprove.png"
                    v-if="item.score < 65"
                  />
                  <img
                    class="opus_words"
                    :src="item.work"
                    @click="eject(item.work)"
                  />
                </div>
                <div class="more flex-align-between">
                  <div class="more_date">
                    {{ item.uploadTime.split(" ")[0] }}
                  </div>
                  <!-- <div class="Check" v-if="screenName == '学生课堂作品'">
                    上榜12次
                  </div> -->
                  <div
                    class="fabulous flex-align-between"
                    v-if="
                      screenName == '学生活动作品' ||
                        screenName == '我的活动作品'
                    "
                  >
                    <img src="@/assets/myactivity/xin.png" />
                    <span>{{ item.likeCount }}</span>
                  </div>
                  <div
                    class="fabulous flex-align-between"
                    v-if="screenName == '作品管理'"
                  >
                    <img
                      :src="
                        item.isExcellent
                          ? require('@/assets/myactivity/xingS.png')
                          : require('@/assets/myactivity/xingK.png')
                      "
                      @click="extension(item)"
                    />
                    <!-- <span>{{item.score}}</span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="EmptyData flex-column-center" v-else>
              <div></div>
              <span>暂无数据</span>
            </div>
          </div>
          <div class="paging flex-align-center">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :page-size="8"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="Footer"></div>
    <div class="appraisalbox">
      <el-dialog
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        center
      >
        <img class="imgword" :src="currentWork" />
        <img
          class="close"
          src="../../assets/datascreen/close.png"
          @click="close"
        />
      </el-dialog>
    </div>
    <div class="deltip">
      <el-dialog
        :visible="dialogVisible"
        width="20%"
        center
        :before-close="clear"
      >
        <span class="spans">{{ tips }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="determine">确定</el-button>
          <el-button @click="clear">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import entrance from "../../Common_components/head/entrance";

export default {
  name: "",
  components: {
    entrance,
  },
  data() {
    return {
      topTitle: "",
      rightTitle: "",
      avatar1: "",
      succeed: false,
      teacherTime: 0,
      teachercount: 0,
      uesrName: "",
      screenList: "",
      screenIndex: 0,
      screenName: "学生课堂作品",
      currentPage4: 1,
      classOptions: [],
      groupOptions: [],
      activityOptions: [],
      taskOptions: [],
      projectOptions: [],
      GradeOptions: [
        {
          id: "",
          name: "AI测评等级",
        },
        {
          id: 1,
          name: "优秀",
        },
        {
          id: 2,
          name: "良好",
        },
        {
          id: 3,
          name: "待提升",
        },
      ],
      situationOptions: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已推优",
        },
        {
          id: 2,
          label: "未推优",
        },
      ],

      classValue: "全部班级",
      groupValue: "全部分组",
      activityValue: "全部活动",
      taskValue: "全部任务",
      projectValue: "全部项目",
      GradeValue: "AI测评等级",
      situationValue: "推优情况",
      valueDate: "",
      inputValue: "",
      classgrades: ["学生课堂作品", "学生活动作品"],
      projectgrades: ["我的作品", "作品管理", "我的活动作品"],
      groupgrades: ["我的作品", "作品管理", "我的活动作品"],
      activitygrades: ["学生活动作品"],
      taskgrades: ["我的作品", "作品管理", "我的活动作品"],
      Timegrades: ["学生课堂作品", "学生活动作品", "我的活动作品"],
      Gradegrades: ["作品管理"],
      situationgrades: ["作品管理"],
      centerDialogVisible: false,
      dialogVisible: false,
      extensionArr: [],
      extensionId: null,
      extensionType: null,
      workList: [],
      option: {
        teamId: "", //项目id
        groupId: "", //分组id
        shareCode: "", //任务id
        level: "", //AI测评等级 1优秀，2良好，3待提升
        isExcellent: "", //是否优秀 1是 0否
        phone: "", //手机号
        page: 1, //页码
        limit: 8, //每页长度
        userName: "", //用户名
        checkType: null, //查询检查类型 1 学生课堂作品；2 学生活动作品；3 我的作品；
        startAt: null, //开始时间戳
        endAt: null, //结束时间戳
        classId: null,
      },
      total: null, //全部条数
      tips: "是否推选为优秀作品?", // 推优文案
      excellentWork: null,
      currentWork: "",
    };
  },
  methods: {
    renewal(value) {},
    changTip(value) {
      if (value) {
        this.hint_data("暂无活动");
        this.centerDialogVisible2 = true;
      }
    },
    information() {
      this.uesrName = sessionStorage.getItem("teacherName");
      let img = sessionStorage.getItem("avatar").replace(/\"/g, "");
      this.avatar1 = "https://yunapi.cnuzi.com/uploads/" + img;
      this.errorimg(this.avatar1);
    },
    //防止闪图
    errorimg(src) {
      let that = this;
      let img = new Image();
      img.src = src;
      img.onload = function() {
        that.succeed = true;
      };
      img.onerror = function() {
        that.succeed = false;
      };
    },
    async getTeacherUploadInfo() {
      this.$Api.Home.getTeacherUploadInfo().then((res) => {
        this.teacherTime = res.data.totalLength;
        this.teachercount = res.data.totalUpload;
      });
    },
    time(time) {
      var h = parseInt(
        ((time * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var m = parseInt(((time * 1000) % (1000 * 60 * 60)) / (1000 * 60));
      var s = parseInt(((time * 1000) % (1000 * 60)) / 1000);
      return h + "时" + m + "分" + s + "秒";
    },
    goback() {
      this.$router.go(-1);
    },
    current(index, name) {
      console.log(index, name);
      this.screenIndex = index;
      this.screenName = name;
      this.classOptions = [];
      this.valueDate = "";
      this.inputValue = "";
      this.option = {
        teamId: "", //项目id
        groupId: "", //分组id
        shareCode: "", //任务id
        level: "", //AI测评等级 1优秀，2良好，3待提升
        isExcellent: "", //是否优秀 1是 0否
        phone: "", //手机号
        page: 1, //页码
        limit: 8, //每页长度
        userName: "", //用户名
        checkType: null, //查询检查类型 1 学生课堂作品；2 学生活动作品；3 我的作品；
        startAt: null, //开始时间戳
        endAt: null, //结束时间戳
        classId: null,
      };
      // 优秀作品判断
      switch (name) {
        case "学生课堂作品":
          this.$set(this.option, "checkType", 1);
          break;
        case "学生活动作品":
          this.$set(this.option, "checkType", 2);
          break;
        case "我的活动作品":
          this.$set(this.option, "checkType", 3);
          break;
      }
      this.search();
    },
    goUser() {
      this.$router.push("/profile");
    },
    goEvaluation() {
      if (this.$route.query.type == 0) {
        this.$router.push({
          path: "/curriculum",
          type: "initial",
        });
      } else {
        this.$router.push({
          path: "/statisticsHome",
        });
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.option.page = val;
      this.search();
    },
    close() {
      this.centerDialogVisible = false;
    },
    eject(work) {
      this.centerDialogVisible = true;
      this.currentWork = work;
    },
    // 查询项目筛选列表
    async getPrejectList(isUser) {
      let data = {
        isUser,
      };
      let resData = await this.$Api.Manage.getPrejectList(data);
      // console.log("项目筛选列表", resData);
      resData.data.unshift({
        name: "全部项目",
        id: "",
      });
      this.projectOptions = resData.data;
    },
    // 查询分组筛选列表
    async getGroupList(teamId) {
      let data = {
        teamId,
      };
      let resData = await this.$Api.Manage.getGroupList(data);
      console.log("分组筛选列表", resData);
      resData.data.unshift({
        name: "全部分组",
        id: "",
      });
      this.groupOptions = resData.data;
    },
    // 查询任务筛选列表
    async getTaskList(groupId) {
      let data = {
        groupId,
      };
      let resData = await this.$Api.Manage.getTaskList(data);
      resData.data.unshift({
        name: "全部任务",
        code: "",
      });
      this.taskOptions = resData.data;
      console.log("任务筛选列表", resData);
    },
    // 根据条件查询作品列表
    async search() {
      this.$route.query.type == 1
        ? (this.option.phone = this.inputValue)
        : (this.option.userName = this.inputValue);
      let resData = await this.$Api.Manage.getWorkList(this.option);
      console.log("作品列表", resData);
      this.total = resData.data.totalCount;
      this.workList = resData.data.list;
    },
    //项目发生变化
    changeProject(val) {
      console.log("当前项目id--", val);
      this.option.teamId = val;
      this.search();
      // 根据项目id获取分组列表
      this.getGroupList(val);
    },
    // 分组发生变化
    changeGroup(val) {
      console.log("当前分组id--", val);
      this.option.groupId = val;
      this.search();
      // 根据分组id获取任务列表
      this.getTaskList(val);
    },
    // 任务发生变化
    changeTask(val) {
      console.log("当前任务id--", val);
      this.option.shareCode = val;
      this.search();
    },
    // AI测评等级发生变化
    changeLevel(val) {
      this.option.level = val;
      this.search();
    },
    // 是否推优发生变化
    changeExcellent(val) {
      this.option.isExcellent = val;
      this.search();
    },
    // 班级发生变化
    changeClass(val) {
      console.log(val);
      this.option.classId = val;
      this.search();
    },
    // 时间发生变化
    changeDate(data) {
      console.log(data);
      if (data) {
        this.option.startAt = new Date(data[0]).getTime() / 1000;
        this.option.endAt = new Date(data[1]).getTime() / 1000;
      } else {
        (this.option.startAt = null), (this.option.endAt = null);
      }
      this.search();
    },
    // 确定推优
    async determine() {
      console.log(this.excellentWork);
      let data = {
        workId: this.excellentWork.workId,
        isPush: this.excellentWork.isExcellent ? 0 : 1,
      };
      let resData = await this.$Api.Manage.excellentWord(data);
      console.log(resData);
      this.clear();
      this.search();
    },
    // 取消推优
    clear() {
      this.dialogVisible = false;
    },
    extension(data) {
      this.dialogVisible = true;
      this.excellentWork = data;
      this.tips = this.excellentWork.isExcellent
        ? "确定取消推选优秀作品?"
        : "确定推选为优秀作品?";
    },
    // 检查是否是管理员
    async checkManager() {
      let resData = await this.$Api.Manage.isManager();
      // console.log('是否是管理员', resData)
      this.getPrejectList(resData.data ? 0 : 1);
    },
    // 查询班级筛选列表
    async getClassList() {
      let resData = await this.$Api.Manage.getClassList();
      console.log("班级列表", resData);
      resData.data.unshift({
        id: "",
        name: "全部班级",
      });
      this.classOptions = resData.data;
    },
    // 学生课堂作品
    stuClassList() {
      this.option.checkType = 1;
      return this.$Api.Manage.getWorkList(this.option).then((res) => {
        // console.log('学生课堂作品', res.data.list);
        return res.data.list;
      });
    },
    // 学生活动作品
    stuActiveList() {
      this.option.checkType = 2;
      return this.$Api.Manage.getWorkList(this.option).then((res) => {
        // console.log('学生活动作品', res.data.list);
        return res.data.list;
      });
    },
    // 我的活动作品
    myActiveList() {
      this.option.checkType = 3;
      return this.$Api.Manage.getWorkList(this.option).then((res) => {
        // console.log('我的活动作品', res.data.list);
        return res.data.list;
      });
    },
  },
  mounted() {
    this.information();
    this.getTeacherUploadInfo();
    if (this.$route.query.type == 0) {
      this.screenList = ["学生课堂作品", "学生活动作品", "我的活动作品"];
      // this.screenName = "学生课堂作品";
      this.topTitle = "优秀作品";
      this.rightTitle = "";
      this.checkManager();
      this.getClassList();
      Promise.all([
        this.stuClassList(),
        this.stuActiveList(),
        this.myActiveList(),
      ])
        .then((res) => {
          console.log(res);
          let delIndex = [];
          res.map((item, index) => {
            if (item.length == 0) {
              // this.screenList.splice(index,1)
              delIndex.push(index);
            }
          });
          console.log("delIndex--", delIndex);
          let str1 = this.screenList[delIndex[0]];
          let str2 = this.screenList[delIndex[1]];
          console.log(str1, str2);
          this.screenList = this.screenList.filter((item) => {
            return item !== str1 && item !== str2;
          });
          console.log("this.screenList--", this.screenList);
        })
        .then(() => {
          this.screenName = this.screenList[0]
          switch (this.screenList[0]) {
            case "学生课堂作品":
              this.option.checkType = 1;
              break;
            case "学生活动作品":
              this.option.checkType = 2;
              break;
            case "我的活动作品":
              this.option.checkType = 3;
              break;
          }
          this.search();
        });
    } else {
      this.search();
      this.getPrejectList();
      this.screenList = ["作品管理"];
      this.screenName = "作品管理";
      this.topTitle = "项目管理";
      this.rightTitle = "查看统计";
    }
  },
};
</script>
<style lang="less" scoped>
@import "./less/surfaceHome.less";
</style>

<style>
.paging .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #21bd99 !important;
  color: #fff !important;
}

.paging .el-pagination.is-background .el-pager li:hover {
  color: #21bd99 !important;
}

.paging .el-pager li.active {
  color: #21bd99 !important;
}

.paging .el-input__inner:focus {
  border-color: #21bd99;
}

.choice .Basics .el-select .el-input {
  border-color: #409eff !important;
  width: 150px !important;
  height: 30px;
  min-height: 30px;
}

.choice .Basics .el-select .el-input .el-input__inner {
  width: 150px !important;
  height: 30px;
  min-height: 30px;
}

.choice .Basics .el-select .el-input .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.choice .Basics .el-select .el-input__inner:focus {
  border-color: #21bd99;
}

.choice .Basics .el-select-dropdown__item.selected {
  color: #21bd99;
}

.choice .Basics .el-date-editor .el-range__icon {
  line-height: 25px !important;
}

.choice .Basics .el-date-editor .el-range__close-icon {
  line-height: 25px !important;
}

.choice .Basics .el-input__inner .el-range-separator {
  line-height: 25px !important;
}

.choice .Basics .el-input__inner {
  width: 300px !important;
  height: 30px;
  line-height: 30px;
}

.search .el-input__inner {
  width: 200px !important;
  height: 30px;
}
</style>
